<i18n>
ru:
  averageTime: Время ожидания заказа
  cancel: Отменить
  details: Подробнее
  enjoyYourMeal: Желаем вам приятного аппетита!
  orderNum: '№{num}'
ua:
  averageTime: Час очікування замовлення
  cancel: Скасувати
  details: Детальніше
  enjoyYourMeal: Бажаємо вам приємного апетиту!
  orderNum: '№{num}'
us:
  averageTime: Order waiting time
  cancel: Cancel
  details: Details
  enjoyYourMeal: We wish you a pleasant meal!
  orderNum: '#{num}'
</i18n>

<template>
  <div
    class="v-container"
    id="Mirrormere"
  >
    <div class="v-row v-px-sm">
      <div
        class="v-col-12 v-col-sm-10 v-col-md-8 v-col-lg-6 v-col-xl-4 v-check-order"
        v-for="order in orders"
        :key="`check-order-${order.ID}`"
      >
        <div class="v-check-order__inside">
          <div
            class="v-d-flex v-justify-content-between v-align-items-start v-mb-xs"
          >
            <div class="v-d-flex v-flex-column v-color-mono-400 v-typography-text-5">
              <span
                v-if="order.Number && order.Number !== '0'"
                v-html="
                  translate('checkOrderMirrormere.orderNum', {
                    num: order.Number
                  })
                "
              />
              <span v-html="dateTime(order.Created * 1000, 'short')" />
            </div>
            <common-order-status
              show-icon
              :icon-left="false"
              :status="order"
            />
          </div>
          <div class="v-mb-sm">
            <hr />
          </div>

          <div
            class="v-typography-title-5 v-d-flex v-check-order__average-time v-mb-md"
            v-if="checkIfStatusSuccessful(order)"
          >
            <span v-html="translate('checkOrderMirrormere.enjoyYourMeal')" />
          </div>
          <div
            class="v-typography-title-5 v-d-flex v-check-order__average-time v-mb-md"
            v-else-if="
              (order.AverageTimeSec > 0 || order.DelayTimeSec > 0) &&
              checkIfStatusInWork(order)
            "
            :class="{
              'v-justify-content-center': !isDesktopVersion
            }"
          >
            <span v-html="translate('checkOrderMirrormere.averageTime')" />
            <span
              class="v-secondary-color"
              v-html="'~'"
            />
            <span class="v-secondary-color v-font-weight-600">
              <common-time
                :time-ms="(order.AverageTimeSec + order.DelayTimeSec) * 1000"
              />
            </span>
          </div>
          <div class="v-d-flex v-justify-content-between v-row">
            <div
              class="v-col-6"
              v-if="order.CanBeCancelled"
            >
              <arora-button
                class-name="v-btn v-btn-border v-secondary v-w-100"
                ignore-settings
                :label="translate('checkOrderMirrormere.cancel')"
                @click="() => cancelOrder(order.ID)"
              />
            </div>
            <div
              class="v-col-6"
              v-else-if="!stringIsNullOrWhitespace(order.TipsLink)"
            >
              <arora-button
                class-name="v-btn v-btn-secondary v-w-100"
                ignore-settings
                :label="translate('tipsPopup.leaveTip')"
                @click="() => goToTipsLink(order)"
              />
            </div>
            <div
              :class="
                order.CanBeCancelled || !stringIsNullOrWhitespace(order.TipsLink)
                  ? 'v-col-6'
                  : 'v-col-12'
              "
            >
              <arora-button
                :class-name="
                  stringIsNullOrWhitespace(order.TipsLink)
                    ? 'v-btn v-btn-secondary v-w-100'
                    : 'v-btn v-btn-border v-secondary v-w-100'
                "
                ignore-settings
                :label="translate('checkOrderMirrormere.details')"
                @click="() => goToOrder(order.ID)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderInfo } from '~types/clientStore'
import type { CheckOrder } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<CheckOrder>()
const { stringIsNullOrWhitespace } = useCommon()
const { dateTime, translate } = useI18nSanitized()
const { isDesktopVersion } = useWindowSize()
const { checkIfStatusInWork, checkIfStatusSuccessful } = useOrderInfo()

function goToTipsLink(order: OrderInfo): void {
  navigateTo(order.TipsLink, {
    external: true,
    open: {
      target: '_blank'
    }
  })
}
</script>

<style lang="scss">
@use 'assets/variables';

#Mirrormere {
  .v-check-order {
    border-radius: variables.$BorderRadius;
    border: 1.5px solid variables.$SecondaryBackgroundColor;
    padding: 4px;
    box-shadow: variables.$CardShadow;

    &__inside {
      background: variables.$White;
      color: variables.$Mono1000;
      border-radius: calc(variables.$BorderRadius - 4px);
      padding: 1.145rem;
    }

    &__average-time {
      gap: 0.215rem;
    }
  }
}
</style>
